<template>
  <!-- Faqs Area Start -->
  <section class="ekifa-faqs-area ">
    <div class="faqs-top-search section_80">
      <b-container>
        <b-row>
          <b-col md="12">
            <div class="faqs-page-top">
              <h2>Fequently asked question</h2>
              <div class="faqs-top-search-form">
                <form>
                  <input placeholder="Type your question here..." type="search">
                  <button type="submit"><i class="fa fa-search"></i></button>
                </form>
              </div>
              <p>Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock.</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="faq-main-area section_80">
      <b-container>
        <b-row>
          <b-col md="12" lg="6">
            <div class="ekifa-faqs-left">
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab" id="headingOne">
                    <b-button v-b-toggle.collapseOne variant="info">
                      <i class="switch fa fa-plus"></i>
                      1. signiferumque Te nec dolor clita partem ?
                    </b-button>
                  </b-card-header>
                  <b-collapse id="collapseOne" visible role="tabpanel" class="p-1">
                    <b-card-body>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                        <img :src="require('../assets/img/service-1.jpeg')" alt="faqs image" />
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab" id="headingTwo">
                    <b-button v-b-toggle.collapseTwo variant="info">
                      <i class="switch fa fa-plus"></i>
                      2. Which Te signiferumque nec dolor partem clita ?
                    </b-button>
                  </b-card-header>
                  <b-collapse id="collapseTwo" role="tabpanel" class="p-1">
                    <b-card-body>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      <img :src="require('../assets/img/service-2.jpg')" alt="faqs image" />
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab" id="headingThree">
                    <b-button v-b-toggle.collapseThree variant="info">
                      <i class="switch fa fa-plus"></i>
                      3. What is the payment process ?
                    </b-button>
                  </b-card-header>
                  <b-collapse id="collapseThree" role="tabpanel" class="p-1">
                    <b-card-body>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        <img :src="require('../assets/img/service-3.jpg')" alt="faqs image" />
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </b-col>
          <b-col md="12" lg="5" offset-lg="1">
            <div class="ekifa-faqs-right">
              <b-form>
                <h3>Didn't find the answer?</h3>
                <b-form-group id="input-group-1" label="Name:" label-for="input-1">
                  <b-form-input
                      id="input-1"
                      v-model="answerItem.name"
                      placeholder="Enter Your Name"
                      required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-2"
                    label="Email address:"
                    label-for="input-2"
                >
                  <b-form-input
                      id="input-2"
                      v-model="answerItem.email"
                      type="email"
                      placeholder="Enter Your Email"
                      required
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-3" label="Subject:" label-for="input-3">
                  <b-form-input
                      id="input-3"
                      v-model="answerItem.subject"
                      placeholder="Enter Your Subject"
                      required
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-4" label="how can we help you?" label-for="input-4">
                  <b-form-textarea
                      id="input-4"
                      size="lg"
                      placeholder="Message..."
                  ></b-form-textarea>
                </b-form-group>
                <b-button type="submit" name="submit">Send message</b-button>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
  <!-- Faqs Area End -->
</template>

<script>
export default {
  name: "FaqArea",
  data() {
    return {
      answerItem: {
        name: '',
        email: '',
        subject: '',
        message: ''
      }
    }
  }
}
</script>

<style scoped>

</style>